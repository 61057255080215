import ClampLines from "react-clamp-lines";
import services_icon from '../img/recycle.png';
import PageIndicator from "../components/PageIndicator";
import { useEffect } from "react";
import '../styles/services.modules.scss';


function Services() {


    const services = [
        {
            id: 1,
            service: 'Residential',
            text: '',

        },
        {
            id: 2,
            service: 'Commercial',
            text: '',

        },
    
        
    ]

    const residentialService = [
        {
            id: 1,
            service: 'Efficient and cost-effective curbside trash and recycling collection.'
        },
        {
            id: 2,
            service: 'Bulky/large item removal.'
        },
        {
            id: 3,
            service: 'House decluttering service which makes it easy to get rid of all your unwanted items.'
        },
        {
            id: 4,
            service: 'Residential powerwashing also provided.'
        },
    ]

    const commercialService = [
        {
            id: 1,
            service: 'We help maintain your property clean by sorting garbage, recycle, and compost.'
        },
        {
            id: 2,
            service: 'We provide hauling services for your property when needed.'
        },
        {
            id: 3,
            service: 'Powerwashing services also provided.'
        },
        {
            id: 4,
            service: 'We can compact garbage, recycle, and compost.'
        },
    ]


    function toTop(){
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      console.log('afterEach')
      }

      useEffect(() => (
        toTop()
      ))


    return (
        <>
        <PageIndicator pageName={'services'} pageLink={'/services'}/>

        <div className='our-services'>
            <h1>
                Our <span className="green">services</span>
            </h1>
            <div className='card-row'>
                {
                    services.map((card) => (
                        <div key={card.id}>
                            <img src={services_icon} alt="logo" />
                            <h1>{card.service}</h1>
                            <ClampLines
                  text={card.text}
                  id="custom"
                  lines={2}
                  moreText="Read More"
                  lessText="Read less"
                  className="custom-class"
                  ellipsis="..."
                  innerElement="p" />
                        </div>
                    ))
                }

            </div>
        </div>

        <div className="header-list">
            <h1>
            RESIDENTIAL SERVICES
            </h1>
            <ul>
                {
                    residentialService.map((list) => (
                        <li key={list.id}>{list.service}</li>
                    ))
                }
            </ul>
        </div>

        <div className="header-list">
            <h1>
            COMMERCIAL SERVICES
            </h1>
            <ul>
                {
                    commercialService.map((list) => (
                        <li key={list.id}>{list.service}</li>
                    ))
                }
            </ul>
        </div>

        </>
    )
}
export default Services;