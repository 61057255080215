import { useEffect } from "react";
import { Link } from "react-router-dom";
import PageIndicator from "../components/PageIndicator"
import allbinspic from '../img/allbinspic.png'
import bin1 from '../img/binspic.jpg'
import bin2 from '../img/bins2pic.jpg'
import trashpic from '../img/trashpic.jpg'
import '../styles/about.modules.scss'





function About() {
    
    
    
    function toTop(){
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      console.log('afterEach')
      }

      useEffect(() => (
        toTop()
      ))

    return (
        <>
        <PageIndicator pageName={'about us'} pageLink={'/about'}/>

        <div className="header-list">
        <h1>Our Mission</h1>
        <p classname="text">
        We are a family-owned business serving our community, and the entire Bay
        Area. F. Avalos Keep the World Clean was founded in 2019 in order to help combat contamination in our community. 
        Since then, we have made it our mission to provide 
        reliable waste management for businesses in San Francisco and the surrounding areas. Call us today for a free estimate! Hablamos español!
         <a href="tel:9252066728">  (925) 206-6728 </a> 
        </p>

        </div>

        <div className='our-services '>
            <div style={{display: 'flex', justifyContent: 'center'}}>

            <Link  className={'btn'} to={'/contact'}>Contact Us</Link>
            </div>
        </div>
    
        
        <div className="organogram-container" >
            
            <img src={allbinspic} alt="" />
        </div>

        <div className="organogram-container" >
            <img src={bin1} alt="" />
        </div>

        <div className="organogram-container" >
            <img src={bin2} alt="" />
        </div>

        <div className="organogram-container" >
            <img src={trashpic} alt="" />
        </div>

        


        </>
    )
}
export default About